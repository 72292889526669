import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signOut, signInWithCustomToken } from "firebase/auth";

export default class Login {
  static async login(username, password) {
    const functions = getFunctions();

    const Login = httpsCallable(functions, "api/userLogin", {
      timeout: 540000,
    });

    const result = await Login({
      id: username,
      password,
    });
    if (result.data.token) {
      const auth = getAuth();
      await signInWithCustomToken(auth, result.data.token);
    }
  }

  static async logout() {
    const auth = getAuth();
    await signOut(auth);
  }

  static async changePassword(user, password, newPassword) {
    try {
      const functions = getFunctions();

      const changePassword = httpsCallable(functions, "api/changePassword");

      const result = await changePassword({
        user,
        password,
        newPassword,
      });
      if (result && result.data.succeeded) return true;
      else return false;
    } catch (err) {
      return err;
    }
  }
}
