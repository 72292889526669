import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const useCallableFunction = (name, options = {}) => {
  const functions = getFunctions();
  const hostname = window.location.hostname;
  if (hostname.match("localhost")) {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  }
  return httpsCallable(functions, name, options);
};

export default useCallableFunction;
