import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import * as Auth from "./modules/auth.js";

import main from "@/main.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: navigator.language.substring(0, 2),
    isDark: false,
    primary: "#002540",
    time: "12",
    shard: 0,
    company: {},
    selectedUser: null,
    selectedUserID: null,
  },
  mutations: {
    setSelectedUser(state, value) {
      state.selectedUser = value;
    },
    setSelectedUserID(state, value) {
      state.selectedUserID = value;
    },
    OPEN_RESULT(state, result) {
      state.active_result = result;
    },
    update_result_regions(state, value) {
      state.active_result.observations = value;
    },
    SET_USER_COMPANY(state, value) {
      state.company = value;
    },
    SET_THEME(state, value) {
      state.isDark = value;
    },
    SET_PRIMARY(state, value) {
      state.primary = value;
    },
    SET_TIME(state, value) {
      state.time = value;
    },
    SET_LANG(state, value) {
      state.lang = value;
      main.i18n.locale = value;
    },
    SET_SHARD(state, value) {
      if (isNaN(value)) state.shard = 0;
      else state.shard = parseInt(value);
    },
  },
  actions: {
    changeTheme({ commit }, value) {
      commit("SET_THEME", value);
    },
    changePrimary({ commit }, value) {
      commit("SET_PRIMARY", value);
    },
    changeLang({ commit }, value) {
      commit("SET_LANG", value);
    },
    changeTime({ commit }, value) {
      commit("SET_TIME", value);
    },
  },
  modules: {
    Auth,
  },
  plugins: [new VuexPersistence().plugin],
});
