import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import devFirebaseConfig from "./dev.firebaseConfig";
import qaFirebaseConfig from "./qa.firebaseConfig";
import productionFirebaseConfig from "./production.firebaseConfig";

const getConfig = () => {
  const { hostname } = window.location;

  if (hostname === "n2p-analytics.web.app" || hostname === "ai.net2phone.com") {
    return productionFirebaseConfig;
  }

  if (hostname === "n2p-ai-qa.web.app") {
    return qaFirebaseConfig;
  }

  if (hostname === "n2p-ai-dev.web.app" || hostname === "localhost") {
    return devFirebaseConfig;
  }

  return null;
};

const app = initializeApp(getConfig());
const auth = getAuth(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);

export default {
  app,
  analytics,
  functions,
  auth,
  db,
};
