import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "UploadView",
    component: () => import("@/views/UploadView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/assessment/:date/:user/:uid",
    name: "Assesment",
    component: () => import("@/views/Assesment.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/Admin.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/users",
    name: "users",
    component: () => import("@/views/Users.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/companies",
    name: "companies",
    component: () => import("@/views/Companies.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    name: "errorpage",
    component: () => import("@/views/ErrorPage.vue"),
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const userClaims = store.state.Auth.token;

  if (to.name === "login" && userClaims) {
    next("/");
    return;
  }

  if (
    (to.name === "admin" || to.name === "users" || to.name === "companies") &&
    userClaims?.role === "agent"
  ) {
    next("error");
    return;
  }

  if (to.name === "admin" && userClaims?.role === "superuser") {
    next();
    return;
  }

  if (!userClaims && to.name === "clientroom" && to.query.id) {
    next();
    return;
  }

  if (requiresAuth && !userClaims) {
    next("/login");
  } else {
    next();
  }
});

export default router;
