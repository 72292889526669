<template>
  <v-app id="app">
    <notifications group="feedback" data-cy="notification" />
    <Navbar />
    <v-main :class="{ showPadding: showPadding }">
      <v-container fluid style="padding: 0px; height: 100%; max-width: 100%">
        <transition name="fade-transition">
          <router-view />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Company from "./models/company.js";
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  data() {
    return {
      showPadding: true,
    };
  },
  components: {
    Navbar,
  },
  computed: {
    ...mapState({
      isLogged: (state) => state.Auth.isLogged,
    }),
  },
  created() {
    this.$i18n.locale = this.$store.state.lang;
    this.$vuetify.theme.dark = this.$store.state.isDark;
    this.$vuetify.theme.themes.dark.primary = this.$store.state.primary;
    this.$vuetify.theme.themes.light.primary = this.$store.state.primary;
    this.subscribeGoogleAuth();
  },
  methods: {
    async subscribeGoogleAuth() {
      const auth = getAuth();

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.$store.dispatch("Auth/userLogin", user);
          this.showPadding = false;
          const companyId = user.uid.split("@")[1];
          const company = await Company.getById(companyId);
          this.$store.commit("SET_USER_COMPANY", company);
          if (company.hourFormat != null) {
            await this.$store.dispatch("changeTime", company.hourFormat);
          }
        } else {
          this.$store.dispatch("Auth/userLogout");
          this.showPadding = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.showPadding {
  padding: 0px !important;
}
#app {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app.dontShow #nav {
  display: none !important;
}
.notifications {
  top: 0px !important;
  width: 310px !important;
  right: 10px !important;
}

.notification-wrapper {
  margin: 10px 0 !important;
  height: auto !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}

.vue-notification {
  font-size: 14px !important;
  margin: -0px 0px 0px 0px !important;
  margin-right: 0px !important;
  cursor: pointer !important;
  height: 100% !important;
}

.vue-notification-group {
  top: 58px !important;
  right: 10px !important;
}

.vue-notification-wrapper {
  border-radius: 8px !important;
  margin-bottom: 5px !important;
}

.vue-notification-template.vue-notification.warning {
  background-color: #ffbc00 !important;
  border-left: 6px solid #ffd150 !important;
}

.vue-notification-template.vue-notification.error {
  border-left-color: #b82e24 !important;
  border-left: 6px solid #ff8484 !important;
}

.vue-notification-template.vue-notification.info {
  background: #1fa9d8 !important;
  border-left: 6px solid #87d1ea !important;
}

.vue-notification-template.vue-notification.success {
  border-left: 6px solid #458047 !important;
}

.vue-notification .notification-title {
  padding-bottom: 5px !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 40px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-btn:focus {
  border: 0px;
  outline: 0px;
}
</style>
