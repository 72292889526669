export default {
  databaseURL: "https://analytics-379120-default-rtdb.firebaseio.com",
  apiKey: "AIzaSyD3wgRMvXnFJg4N-jLZQ12mlNdQshszej0",
  authDomain: "analytics-379120.firebaseapp.com",
  projectId: "analytics-379120",
  storageBucket: "analytics-379120.appspot.com",
  messagingSenderId: "759277164215",
  appId: "1:759277164215:web:1d5d60428ec9d3d9411f32",
  measurementId: "G-LHQML9K2RN",
};
