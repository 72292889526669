<template>
  <div>
    <v-app-bar
      app
      :color="$store.state.Auth.isLogged ? 'primary' : 'transparent'"
      id="nav"
      style="box-shadow: 0px 0px"
      dense
    >
      <v-img
        @click="$router.replace('/')"
        style="cursor: pointer"
        alt="Logo"
        class="shrink ml-2"
        contain
        v-if="$store.state.Auth.isLogged"
        :src="'https://www.net2phone.com/hs-fs/hubfs/NB%202021/logos/n2p-logo-blue.png?width=432&name=n2p-logo-blue.png'"
        transition="scale-transition"
        width="100"
      />
      <v-spacer />

      <template v-if="$store.state.Auth.isLogged">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <Settings />
            </div>
          </template>
          <span data-cy="settingsMenutt">{{ $i18n.t("SETTING.title") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              style="overflow: hidden"
              small
              depressed
              color="#d75d5d"
              fab
              v-on="on"
              data-cy="nameMenu"
            >
              <span class="white--text headline">{{ initials }}</span>
            </v-btn>
          </template>
          <span data-cy="nameMenutt">{{
            $store.state.Auth.token.fullname
          }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="logout()"
              v-on="on"
              icon
              class="ml-2"
              data-cy="logoutbtn"
            >
              <v-icon>mdi-exit-run</v-icon>
            </v-btn>
          </template>
          <span data-cy="logoutbtntt">Logout</span>
        </v-tooltip>
      </template>

      <template
        v-slot:extension
        class="navbarExtension"
        v-if="usageLimitReached"
      >
        <div class="usageLimitHeader">
          {{ $i18n.t("COMPANY.monthlyUsageReachedBanner") }}
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import Settings from "@/components/Settings.vue";
import { mapState } from "vuex";
import Login from "./../models/login.js";
import { useValidateUsageLimit } from "./../hooks/useValidateUsageLimit";

export default {
  data() {
    return {
      fab: false,
      usageLimitReached: false,
    };
  },
  components: {
    Settings,
  },
  methods: {
    async logout() {
      await Login.logout();
      this.$router.replace("/login");
      this.dialogConfirm = false;
    },
    async validateUsageLimit() {
      try {
        const { limitReached } = await useValidateUsageLimit();
        if (limitReached) this.usageLimitReached = true;
      } catch (e) {
        console.error(e);
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error while trying to validate monthly seconds billed",
        });
      }
    },
  },
  computed: {
    ...mapState({
      initials: (state) => {
        const arr = state.Auth.token.fullname.split(" ");
        if (arr.length === 1) {
          return arr[0][0].toUpperCase();
        } else {
          return (arr[0][0] + arr[1][0]).toUpperCase();
        }
      },
    }),
  },
  mounted() {
    this.ctAux = document.createElement("canvas");
    this.ctAux.id = "imgResize-virtual-background";
    this.ctxAux = this.ctAux.getContext("2d");
    this.ctAux.style.display = "none";
    document.body.appendChild(this.ctAux);
    this.validateUsageLimit();
  },
};
</script>

<style lang="scss">
.logoOpacity {
  opacity: 0.5 !important;
}
.v-toolbar__content {
  padding-left: 3px !important;
}
.v-speed-dial__list button.theme--light {
  background-color: #f5f5f5 !important;
}
.v-speed-dial__list button.theme--dark {
  background-color: #272727 !important;
}
.v-toolbar__extension {
  padding: 0;
  display: flex;
  justify-content: space-around;
}
.usageLimitHeader {
  background-color: red;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
